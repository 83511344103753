import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { QuillModule } from 'ngx-quill';

import { LookupModule } from '../../components/lookup/lookup.module';
import { SafeHtmlModule } from '../../pipes/safe-html/safe-html.module';

import { TextEditorNewComponent } from './text-editor-new.component';

@NgModule({
  declarations: [TextEditorNewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    LookupModule,
    NgPipesModule,
    NzSelectModule,
    SafeHtmlModule,
    QuillModule,
  ],
  exports: [TextEditorNewComponent],
})
export class TextEditorNewModule {}
