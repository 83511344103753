import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillModules, QuillToolbarConfig } from 'ngx-quill';

@Component({
  selector: 'by-text-editor-new',
  templateUrl: './text-editor-new.component.html',
  styleUrls: ['./text-editor-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextEditorNewComponent,
      multi: true,
    },
  ],
})
export class TextEditorNewComponent implements ControlValueAccessor, OnChanges {
  @Input()
  toolbar: QuillToolbarConfig = [
    [
      'bold',
      'italic',
      'underline',
      'strike',
      // { header: 1 },
      // { header: 2 },
      { list: 'bullet' },
      { list: 'ordered' },
      { align: [] },
      // { color: [] },
      // { background: [] },
      // 'link',
    ],
  ];

  value: string;

  onChange: (value: string) => void;

  onTouch: () => void;

  modules: QuillModules = {
    toolbar: this.toolbar,
  };

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const { toolbar } = changes;

    if (toolbar && this.toolbar) {
      this.modules = {
        ...this.modules,
        toolbar: this.toolbar,
      };
    }
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnTouched(onTouch: () => void) {
    this.onTouch = onTouch;
  }

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }
}
