<div class="by-text-editor-new">
  <quill-editor
    class="by-w-100"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [placeholder]="'insert_text' | translate | upperfirst"
    [modules]="modules"
    bounds="self"
  ></quill-editor>
</div>
